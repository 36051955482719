<template>
	<div>
		<el-form ref="defForm" :rules="rules" :model="defForm" label-width="80px">
			<el-form-item label="岗位名称" prop="name">
				<el-input v-model="defForm.name"></el-input>
			</el-form-item>
			<el-form-item label="招聘人数" prop="quantity">
				<el-input-number v-model="defForm.quantity" />
			</el-form-item>
			<el-form-item label="学历要求" prop="degree">
				<el-select v-model="defForm.degree" placeholder="请选择栏目类型"> 
					<el-option v-for="(item,index) in this.degreeList" :key="index" :label="item.label" :value="item.label">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="工作地点" prop="workplace">
				<el-input v-model="defForm.workplace"></el-input>
			</el-form-item>
			<el-form-item label="薪资待遇" prop="treatment">
				<el-input type="textarea" autosize v-model="defForm.treatment"></el-input>
			</el-form-item>
			<el-form-item label="任职要求" prop="request">
				<el-input type="textarea" autosize v-model="defForm.request"></el-input>
			</el-form-item>
			<el-form-item label="工作内容" prop="content">
				<el-input type="textarea" autosize v-model="defForm.content"></el-input>
			</el-form-item>
			<el-form-item size="large">
				<el-button type="primary" @click="close">取消</el-button>
				<el-button type="primary" :loading="loading" @click="submitForm">保存</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>
<script>
	import {create} from '@/api/recruit'
	import token from '@/token.js'
	export default {
		name: "Add",
		data() {
			return {
				defForm: {
					typeId: 0,
					name: '',
					quantity: 0,
					degree:'',
					workplace: '',
					treatment: '',
					request: '',
					content: '',
					createUserID: token.getUser().id,
					createUserName: token.getUser().manager_username
				},
				degreeList:[
					{label:'高中'},
					{label:'专科'},
					{label:'本科'},
					{label:'硕士'}
				],
				loading: false,
				rules: {
					name: [{
						required: true,
						message: '必填项不能为空'
					}],
				}
			}
		},
		methods: {
			submitForm() {
				console.log("新增岗位",this.defForm)
				this.$refs['defForm'].validate(valid => {
					if (valid) {
						this.loading = true
						create(this.defForm).then(data => {
							this.loading = false
							this.$message.success("招聘岗位["+this.defForm.name+"]新增成功!")
							this.$refs['defForm'].resetFields()
							this.$emit("after")
							this.$emit("hideDialog")
						}).catch(error => {
							this.loading = false
							this.$message.error(error)
						})
					}
				})
			},
			close() {
				this.$emit("hideDialog")
			}
		}
	}
</script>

<style scoped>

</style>
