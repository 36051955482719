import axiosInstance from "./axiosInstance";
//新增岗位
export function create(param) {
	return axiosInstance.post('recruit/addRecruit', param)
}
//编辑岗位
export function update(param) {
	return axiosInstance.post('recruit/editRecruit', param)
}
//删除岗位
export function del(param) {
	return axiosInstance.post('recruit/deleteRecruit', param)
}
//分页查询岗位列表
export function query(param) {
	return axiosInstance.post('recruit/queryRecruitList', param)
}
